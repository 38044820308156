import {changeViewport} from 'common/utils/change-viewport'

export const DESKTOP_SOCIAL = [
  {
    href: 'https://vk.com/rambler',
    code: 'vk'
  },
  {
    href: 'https://ok.ru/rambler',
    code: 'ok'
  },
  {
    href: 'tg://resolve?domain=news_rambler',
    code: 'tg'
  },
  {
    href: 'https://news.rambler.ru/rss/',
    code: 'rss'
  }
]

export const DESKTOP_MENU = [
  {
    title: 'Мобильная версия',
    code: 'desktop',
    onClick: () => changeViewport({isMobile: true})
  },
  {
    title: 'Реклама',
    code: 'advertising',
    href: 'https://help.rambler.ru/reklama/?about=1',
    target: '_blank'
  },
  {
    title: 'Помощь',
    code: 'help',
    href: 'https://help.rambler.ru/feedback/www/',
    target: '_blank'
  },
  {
    title: 'Вакансии',
    code: 'vacancy',
    href: 'https://ramblergroup.com/career/',
    target: '_blank'
  },
  {
    title: 'Условия использования',
    code: 'terms',
    href: 'https://help.rambler.ru/legal/1430/',
    target: '_blank'
  },
  {
    title: 'Политика конфиденциальности',
    code: 'confid',
    href: 'https://help.rambler.ru/legal/1142/',
    target: '_blank'
  }
]

export const MOBILE_SOCIAL = [
  {
    href: 'https://vk.com/rambler',
    code: 'vk'
  },
  {
    href: 'https://ok.ru/rambler',
    code: 'ok'
  },
  {
    href: 'tg://resolve?domain=news_rambler',
    code: 'tg'
  },
  {
    href: 'https://news.rambler.ru/rss/',
    code: 'rss'
  }
]

export const MOBILE_LINKS = [
  {
    title: 'Полная версия',
    code: 'desktop',
    onClick: () => changeViewport({isMobile: false})
  },
  {
    title: 'Помощь',
    code: 'help',
    href: 'https://help.rambler.ru/feedback/www/',
    target: '_blank'
  },
  {
    title: 'Политика конфиденциальности',
    href: 'https://help.rambler.ru/legal/1142/',
    code: 'legal',
    target: '_blank'
  },
  {
    title: 'Условия использования',
    code: 'terms',
    href: 'https://help.rambler.ru/legal/1430/',
    target: '_blank'
  }
]
