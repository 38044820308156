import React, {useState} from 'react'
import clsx from 'clsx'

import RamblerTopline, {type UserProfileType} from '@rambler-components/topline'
import RamblerHeader from '@rambler-components/header'
import RamblerHeaderExtension from '@media-components/header-extension'
import {Geo} from 'common/components/geo'
import {Ad} from 'common/components/ad'
import {Popup} from 'common/components/popup'
import {AD_TPLNPROMO_ID} from 'common/constants/ad'

import '@rambler-components/topline/styles.css'
import '@rambler-components/header/styles.css'
import '@media-components/header-extension/styles.css'
import styles from './styles.module.css'

interface ToplineDesktopProps {
  adParams?: {adTransitionCounter: number; routeName: string}
}

export const ToplineDesktop: React.FC<ToplineDesktopProps> = ({adParams}) => {
  const [userFetched, setUserFetched] = useState(false)
  const [userProfile, setUserProfile] = useState<UserProfileType | null>(null)
  const [isGeoOpened, setGeoOpened] = useState(false)
  const [reloadGeoKey, updateReloadGeoKey] = useState(0)

  return (
    <div className={clsx(styles.header, 'ad_branding_header')}>
      <RamblerTopline
        reloadGeoKey={reloadGeoKey}
        promoAd={
          adParams ? <Ad id={AD_TPLNPROMO_ID} adParams={adParams} /> : null
        }
        onUserFetched={() => setUserFetched(true)}
        onOpenGeo={() => setGeoOpened(true)}
        onUserInfo={setUserProfile}
      />
      <RamblerHeader
        projectCode="tv"
        projectName="Телепрограмма"
        userFetched={userFetched}
        userProfile={userProfile}>
        <RamblerHeaderExtension reloadKey={reloadGeoKey} />
      </RamblerHeader>
      <Popup
        width={560}
        isOpen={isGeoOpened}
        onClose={() => setGeoOpened(false)}>
        <Geo
          onChange={() => updateReloadGeoKey(reloadGeoKey + 1)}
          onClose={() => setGeoOpened(false)}
        />
      </Popup>
    </div>
  )
}
