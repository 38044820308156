import React from 'react'
import clsx from 'clsx'

import RamblerFooter, {
  type MenuLinkType,
  type SocialLinkType
} from '@rambler-components/footer'

import {
  DESKTOP_SOCIAL,
  DESKTOP_MENU,
  MOBILE_SOCIAL,
  MOBILE_LINKS
} from './constants'

import '@rambler-components/footer/styles.css'
import styles from './styles.module.css'

interface FooterProps {
  isMobile?: boolean
}

export const Footer: React.FC<FooterProps> = ({isMobile}) => {
  const socialLinks = isMobile ? MOBILE_SOCIAL : DESKTOP_SOCIAL
  const menuLinks = isMobile ? MOBILE_LINKS : DESKTOP_MENU

  return (
    <div className={clsx(styles.footer, 'ad_branding_footer')}>
      <RamblerFooter
        socialLinks={socialLinks as SocialLinkType[]}
        menuLinks={menuLinks as MenuLinkType[]}
      />
    </div>
  )
}

export {MOBILE_SOCIAL, MOBILE_LINKS}
